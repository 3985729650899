import React, { ReactNode } from "react";
import { Link } from "gatsby";
import classNames from "classnames";
import { ButtonProps } from "react-bootstrap/Button";

interface LinkButtonProps {
  readonly to: string;
  readonly variant: ButtonProps["variant"];
  readonly size: ButtonProps["size"];
  readonly className?: string;
  readonly children: ReactNode;
}

// Link in the style of a bootstrap button
function LinkButton({
  to,
  className,
  size,
  variant,
  children,
}: LinkButtonProps) {
  // Classname basically copied from react-bootstrap button source
  const prefix = "btn";
  return (
    <Link
      to={to}
      className={classNames(
        className,
        prefix,
        variant && `${prefix}-${variant}`,
        size && `${prefix}-${size}`
      )}
    >
      {children}
    </Link>
  );
}

export default LinkButton;
