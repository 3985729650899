import React from "react";
import { Link } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Logo from "~/images/logo.svg";
import LinkButton from "./linkButton";

function Header() {
  return (
    <header>
      <Container>
        <Row>
          <Col>
            <div className="navbar">
              <Link to="/">
                <img
                  src={Logo}
                  className="logo"
                  alt="New Energy Academy logo"
                />
              </Link>
              <div className="nav">
                <a
                  href={process.env.GATSBY_LOGIN_LINK}
                  className="no-underline"
                >
                  Sign in
                </a>
                <LinkButton to="/pathways/" variant="primary" size="sm">
                  Start
                </LinkButton>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </header>
  );
}

export default Header;
