import React, { ReactNode } from "react";
import Header from "./header";
import "@fontsource/inter/variable-full.css";

interface LayoutProps {
  readonly children: ReactNode | ReadonlyArray<ReactNode>;
}

function Layout({ children }: LayoutProps) {
  return (
    <>
      <Header />
      <div>
        <div>{children}</div>
      </div>
    </>
  );
}

export default Layout;
